class User {
  firstname: string;
  middlename: string;
  lastname: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  preferences: string[];
  following: string[];
  followers: [];
  affiliatecode: string;
  marketplacelink: string;
  profileimg: string;
  myvideos: string[];
  likedvideos: string[];
  aboutme: string;
  numberofsubscribers: number;
  coverphotourl: string;
  homePageCategory: string[];
  isLoggedIn: boolean;
  notifications: string[];
  friends: string[];
  liked_videos: string[];
  constructor(
    firstname: string,
    lastname: string,
    email: string,
    username: string,
    password: string,
    profileimg: string,
    coverphotourl: string,
    homePageCategory: string[],
    isLoggedIn: boolean,
    shop: string,
    notifications: string[],
    friends: string[]
  ) {
    this.isLoggedIn = false;
    this.homePageCategory = ["Videos", "Shorts", "Shops", "Music", "Articles"];
  }
  login(): void {
    this.isLoggedIn = true;
    console.log("YO");
  }
  logout(): void {
    this.isLoggedIn = false;
    this.username = "";
  }
}

export default User;
