import { Link } from "react-router-dom";
import { loadVideoByCategory } from "../../utils/loadVideoByCategory";
import { useEffect, useState } from "react";

function ItemsCategoryList({ listOfCategory }) {
  return (
    <>
      <CategoryList categorylist={listOfCategory} />
    </>
  );
}
export default ItemsCategoryList;

function CategoryList({ categorylist }) {
  return (
    <>
      <ul className="category-list">
        {categorylist.map((category, index) => (
          <Catergory key={index} category={category} />
        ))}
      </ul>
    </>
  );
}
function Catergory({ category }) {
  const [list_of_items, setList] = useState([]);
  var category_title = category.toLowerCase().replace(/\s/g, "");

  useEffect(() => {
    loadVideoByCategory(category_title, list_of_items, setList);
  }, []);

  return (
    <li
      className={
        "category-wrapper " + category.toLowerCase().replace(/\s/g, "")
      }
    >
      <div className="category-wrapper-head">
        <h1 className="category-title">{category}</h1>
        <Link to={"explore/" + category.toLowerCase().replace(/\s/g, "")}>
          <p>
            Explore more
            <img src="/svg/arrow-up-right.svg"></img>
          </p>
        </Link>
      </div>
      <hr style={{ color: "white" }} />

      {/* <button className="prevtBtn">
          <img src="/svg/arrow-left.svg" alt=""></img>
        </button> */}

      <ItemList type={category} list_of_items={list_of_items} />

      {/* <button className="nexttBtn">
          <img src="/svg/arrow-right.svg" alt=""></img>
        </button> */}
    </li>
  );
}

function ItemList({ type, list_of_items }) {
  var list = [];
  list_of_items.map((e) => {
    if (list.length < 4) {
      list.push(<Item key={e.video_id} type={type} item={e} />);
    }
  });

  return <ul className="item-list">{list}</ul>;
}
function Item({ type, item }) {
  var item;
  switch (type) {
    case "Shops":
      item = <ShopPreviewCard item={item} type={type} />;
      break;
    case "Articles":
      item = <ArticlePreviewCard item={item} type={type} />;
      break;
    default:
      item = <VideoPreviewCard_m item={item} type={type} />;
      break;
  }

  return <>{item}</>;
}

function VideoPreviewCard_m({ item }) {
  return (
    <li className="video-card">
      <figure>
        <div className="video-preview">
          <Link to={`/play/${item.video_id}`}>
            <img src={item.thumbnail_url} alt="" />
            <span className="duration">00:00</span>
          </Link>
        </div>
        <figcaption className="video-info">
          <div className="author-image">
            <Link to="">
              <img src={item.thumbnail_url} alt="" />
            </Link>
          </div>

          <div className="video-card-caption">
            <Link to="/play">
              <h5>{item.title}</h5>
            </Link>
            <span>
              <Link to="">{item.author_id}</Link>
            </span>
          </div>
        </figcaption>
      </figure>
    </li>
  );
}

function ArticlePreviewCard({ item }) {
  return (
    <li className="article-card">
      <Link to={`/play/${item.video_id}`}>
        <div className="article-preview">
          <img src={item.thumbnail_url} alt="" />
        </div>
      </Link>
      <div className="article-info">
       
        <div className="author-image">
          <Link to="">
            <img src={item.thumbnail_url} alt="" />
          </Link>
        </div>
        <div className="article-card-caption">
          <Link to="/play">
            <h5>{item.title}</h5>
          </Link>
          <span>
            <Link to="">{item.description}</Link>
          </span>
        </div>
      </div>
    </li>
  );
}
function ShopPreviewCard({ item }) {
  return (
    <li className="shop-card">
      <figure>
        <Link className="shop-logo" to="/">
          <img src={item.thumbnail_url} alt="" />
        </Link>
        {/* <figcaption className="shop-info">
          <Link to="">
            <h5>{item.author_id}</h5>
          </Link>
        </figcaption> */}
      </figure>
    </li>
  );
}
