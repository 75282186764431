import { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

function MessageCardPanel({toggleForm, isOn,  setUser}) {
  
  const [currentForm, setForm] = useState("login")
  

  return (
    <>
      <div onClick={toggleForm} className={isOn ? "panel-container on" : "panel-container "}>
        <section className={currentForm === "register" ? "panel register" : " panel"}>
          
          <div className="card" onClick={(e=>e.stopPropagation())}>
            <img alt='' src="/images/logo.png" className="card-img-top"
              style={{ width: "150px", alignSelf: "center" }} />
            <div className="card-body">
              
              <LoginForm  toggleForm={toggleForm} setUser={setUser} setForm={setForm}   />
              <RegisterForm setForm={setForm} />
              
              
            </div>
          </div>
        </section>
      </div>
    </>)
}

export default MessageCardPanel;





function SuccessMsg(){

  return (
  <>

  </>)

}
function FailMsg(){

  return (
  <>
  
  </>)

}