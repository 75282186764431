
function GenralSettingFrom() {
    const GenderOptions = ["Male", "Female"];
    const CountryOptions = ["China", "India"]

    return (
        <form className="general" >
            <h4 style={{ width: '100%' }}>Personal Information</h4>
            <hr />
            <InputField label={"First Name"} id={"firstname"} type={""}/>
            <InputField label={"Last name"} id={"lastname"} type={""} />
            <InputField label={"Username"} id={"username"} type={""} />
            <InputField label={"Email"} id={"email"} type={""}/>
            <InputField label={"Gender"} id={"genderselect"} type={"selection"} options={GenderOptions}/>            
            <InputField label={"Country"} id={"countryselect"} type={"selection"} options={CountryOptions}/>        
            <InputField label={"Age"} id={"age"} type={""}/>            
            <InputField label={"About"} id={"aboutme"} type={"ta"}/>            
            <h4 style={{ width: '100%' }}>Links</h4>
            <hr />
            <InputField label={"Facebook"} id={"facebooklink"} type={""}/>
            <InputField label={"Twitter"} id={"twitterink"} />
            <InputField label={"Instagram"} id={"instagramink"} type={""} />
            <div style={{width:'50%'}}>
                <button className="p-btn save-btn">Save</button>
            </div>

        </form>
    )

}
export default GenralSettingFrom;

export function InputField({ label, id, type, options}) {
    var item;
    switch (type) {
        case "selection":
            var listOfOptions = [];
            options.map(x => {
                listOfOptions.push(<option>{x}</option>)
            })
            item =
                <div className="item">
                    
                    <select id={id}>
                        <option>Select {label}</option>
                        {listOfOptions}                  
                    </select>
                </div>
            break;
            case "ta":
                item =
                <div className="item" style={{ width: '90%' }}>
                <label htmlFor={id}>{label}</label>
                <textarea style={{ backgroundColor: 'transparent' }} id={id}></textarea>
            </div>
                break;
            case "":
                item =  <div className="item">
                <label htmlFor={id}>{label}</label>
                <input id={id}></input>
            </div>
                break;
    }
    return (
        <>  {item}</>
      
       )
}