import { useState } from "react";
import './wallet.css'

function Wallet() {
  const [amount, setAmount] = useState(0);

  return (
    <>
      <div className="wallet-wrapper">
        <div className="content">
            <h2>Available Balance</h2>            
          <div>           
            <h1>${amount.toPrecision(3)}</h1>
            <hr/>
          </div>
          <div className="buttons-cont">
          <ul className="list-of-btns">
            <li>
              <TopUpButton />
            </li>
            <li>
              <WithdrawButton />
            </li>
            <li>
              <TransferButton />
            </li>
          </ul>
          </div>
         
        </div>
      </div>
    </>
  );
}
export default Wallet;

function TopUpButton() {
  return (
    <>
      <button><img src="/svg/wallet/topup.svg" />Top Up</button>
    </>
  );
}
function WithdrawButton() {
  return (
    <>
      <button><img src="/svg/wallet/withdraw.svg" />Withdraw</button>
    </>
  );
}
function TransferButton() {
  return (
    <>
      <button><img src="/svg/wallet/send.svg" />Deposit</button>
    </>
  );
}
