import "./videoplaypage.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Video from "../../models/video.ts";

function VideoPlayPage() {
  const [video, setVideo] = useState(new Video());
  let params = useParams();
  document.querySelector('header').style.position = "relative"
  function loadVideobyID() {
    axios
      .get("/videos.json")
      .then((response) => {
        const videos = response.data;
        setVideo(videos.find((u) => u.video_id === params.video_id));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    loadVideobyID();
  }, [params.video_id]);

  return (
    <>
      <div className="wrapper player">
        <div className="video-play-main">
          <div className="video-play-left-section">
            <VideoPlayer video={video} />
          </div>
          <div className="video-play-right-section">
            <div className="recommended-videos">
              <ListOfRecommendations />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoPlayPage;
function GetVideo() {
  const vid = {
    title: "Valorant Deatchmatch Live stream",
    descrition: "ola bro",
    url: "https://pub-a896e3be7cc84c3fafc525a76fe798f3.r2.dev/SpeechSDK_preview.mp4",
    author: "Amanuel",
  };
  return vid;
}
function VideoPlayer({ video }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoContainer = document.querySelector(".video-container");
  const videoPlayer = document.querySelector(".playing-video");
  const volumeSlider = document.querySelector(".volume-slider");
  const previewImg = document.querySelector(".preview-img");
  const thumbnailImg = document.querySelector(".thumbnail-img");
  const timelineContainer = document.querySelector(".timeline-container");
  let isScrubbing = false;
  let wasPaused;
  function toggleScrubbing(e) {
    const rect = timelineContainer.getBoundingClientRect();
    const percent =
      Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
    isScrubbing = (e.buttons & 1) === 1;
    videoContainer.classList.toggle("scrubbing", isScrubbing);
    if (isScrubbing) {
      wasPaused = videoPlayer.paused;
      videoPlayer.pause();
    } else {
      videoPlayer.currentTime = percent * videoPlayer.duration;
      if (!wasPaused) videoPlayer.play();
    }
    handleTimelineEvent(e);
  }
  function handleTimelineEvent(e) {
    const rect = timelineContainer.getBoundingClientRect();
    const percent =
      Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
    const previewImgNumber = Math.max(
      0,
      Math.floor((percent * videoPlayer.duration) / 10)
    );
    const previewImgSrc = "/images/logo.jpg";
    previewImg.style.objectFit = "cover"
    previewImg.src = previewImgSrc;
    timelineContainer.style.setProperty("--preview-position", percent);
    if (isScrubbing) {
      e.preventDefault();
      thumbnailImg.src =
        previewImgSrc /
        timelineContainer.style.setProperty("--progress-position", percent);
    }
  }
  const togglePlay = () => {
    if (videoPlayer.paused) {
      videoPlayer.play().then(() => setIsPlaying(true));
    } else {
      videoPlayer.pause();
      setIsPlaying(false);
    }
  };

  function toggleTheaterMode() {
    videoContainer.classList.toggle("theater");
  }
  //Working
  function toggleFullScreen() {
    if (document.fullscreenElement == null) {
      videoContainer.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }
  function toggleMiniPlayer() {
    // videoContainer.classList.toggle("mini-player");
    if (videoContainer.classList.contains("mini-player")) {
      document.exitPictureInPicture();
    } else {
      videoPlayer.requestPictureInPicture();
    }
  }
  const toggleMute = () => (videoPlayer.muted = !videoPlayer.muted);
  function setVolume(e) {
    videoPlayer.volume = e.target.value;
    videoPlayer.muted = e.target.value === 0;
  }
  function handleVolumeChange() {
    volumeSlider.value = videoPlayer.volume;
    let volumeLevel;
    const videoContainer = document.querySelector(".video-container");
    if (videoPlayer.muted || videoPlayer.volume === 0) {
      volumeSlider.value = 0;
      volumeLevel = "muted";
    } else if (videoPlayer.volume >= 0.5) {
      volumeLevel = "high";
    } else {
      volumeLevel = "low";
    }
    videoContainer.dataset.volumeLevel = volumeLevel;
  }
  function changePlaybackSpeed(e) {
    let speed = videoPlayer.playbackRate + 0.25;
    if (speed > 2) speed = 0.25;
    videoPlayer.playbackRate = speed;
    e.target.textContent = `${speed}x`;
  }
  function timeUpdate() {
    const currentTimeElem = document.querySelector(".current-time");
    currentTimeElem.textContent = formatDuration(videoPlayer.currentTime);
    const percent = videoPlayer.currentTime / videoPlayer.duration;
    timelineContainer.style.setProperty("--progress-position", percent);
  }
  const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 2,
  });
  function formatDuration(time) {
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor(time / 60) % 60;
    const hours = Math.floor(time / 3600);
    if (hours === 0) {
      return `${minutes}:${leadingZeroFormatter.format(seconds)}`;
    } else {
      return `${hours}:${leadingZeroFormatter.format(
        minutes
      )}:${leadingZeroFormatter.format(seconds)}`;
    }
  }
  function skip(duration) {
    videoPlayer.currentTime += duration;
  }
  function setDuration() {
    const totalTimeElem = document.querySelector(".total-time");
    totalTimeElem.textContent = formatDuration(videoPlayer.duration);
  }
  return (
    <>
      <div
        className={isPlaying ? "video-container" : "video-container paused "}
        data-volume-level="high"
      >
        <img src="" alt="" className="thumbnail-img" />
        <div className="video-controls-container">
          <div
            className="timeline-container"
            onMouseDown={toggleScrubbing}
            onMouseMove={handleTimelineEvent}
          >
            <div className="timeline">
              <img src="" alt="" className="preview-img" />
              <div className="thumb-indicator"></div>
            </div>
          </div>
          <div className="controls">
            <button className="play-pause-btn" onClick={togglePlay}>
              <img
                className="bi bi-pause-fill pause-icon"
                src="/svg/pause-icon.svg"
              />
              <img
                className="bi bi-play-fill play-icon"
                src="/svg/play-icon.svg"
              />{" "}
            </button>
            <div className="volume-container">
              <button className="volume-control" onClick={toggleMute}>
                <img
                  className="bi bi-volume-mute-fill"
                  src="/svg/volume-mute.svg"
                />
                <img
                  className="bi bi-volume-down-fill"
                  src="/svg/volume-low.svg"
                />
                <img
                  className="bi bi-volume-up-fill"
                  src="/svg/volume-high.svg"
                />
              </button>
              <input
                onChange={(e) => setVolume(e)}
                className="volume-slider"
                type="range"
                min="0"
                max="1"
                step="any"
                value="1"
              />
            </div>
            <div className="duration-container">
              <div className="current-time">0:00</div>/
              <div className="total-time">10:00</div>
            </div>
            <button
              onClick={(e) => changePlaybackSpeed(e)}
              className="speed-btn wide"
            >
              1x
            </button>
            <button onClick={toggleMiniPlayer} className="mini-player-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-square-half"
                viewBox="0 0 16 16"
              >
                <path d="M8 15V1h6a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H8zm6 1a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12z" />
              </svg>
            </button>
            <button
              onClick={toggleTheaterMode}
              className="theater-btn"
            ></button>
            <button onClick={toggleFullScreen} className="full-screen-btn">
              <img
                className="bi bi-fullscreen"
                src="/svg/full-screen-icon.svg"
              />
              <img
                className="bi bi-fullscreen-exit"
                src="/svg/full-screen-exit-icon.svg"
              />
            </button>
          </div>
        </div>
        <video
          onVolumeChange={handleVolumeChange}
          onClick={togglePlay}
          onTimeUpdate={timeUpdate}
          onLoadedData={setDuration}
          className="playing-video"
          src={video.url}
          playsInline
        ></video>
      </div>
      <div style={{}}>
        <div className="video-info-container">
          <div className="video-author-img">
            <img src="/assets/images/ww.jpg" alt="" />
          </div>
          <div className="video-title-author">
            <h4>
              {video.title}{" "}
              <span style={{ display: "block" }}>
                {" "}
                <a href=""></a>
                <span
                  style={{ display: "block", fontSize: "small", color: "grey" }}
                >
                  1,000,000 subscribers
                </span>
              </span>
            </h4>
          </div>
          <div className="feedback-btns">
            <p style={{ color: "grey" }}>1,000,000 views</p>
            <ul>
              <li>
                <button className="like-btn">
                  <img
                    className="bi bi-hand-thumbs-up"
                    src="/svg/thumbsUp.svg"
                  />
                  <img
                    className="bi bi-hand-thumbs-up-fill"
                    src="/svg/thumbsUpfill.svg"
                  />
                  <p>100,000</p>
                </button>
              </li>
              <li>
                <button>
                  <img src="/svg/share-icon.svg" />
                </button>
              </li>
              <li>
                <button>
                  <img src="/svg/flag-fill.svg" />
                </button>
              </li>
            </ul>
            <button className="p-btn">Subscribe</button>
          </div>
        </div>
        <div className="description">
          <h3>
            Description
            <hr />
          </h3>
          <p style={{ color: "white" }}>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloribus
            enim dolore vel dolores perspiciatis ducimus est voluptate ipsum
            quas maiores, exercitationem et nobis ad hic, placeat quo
            reprehenderit nesciunt expedita.
          </p>
        </div>

        <div className="comment">
          <h3 style={{ color: "white" }}>
            Comments
            <hr />
          </h3>
          {/* {u.username?<section>
            <form action="">
              <textarea className='comment-input-area' name="" id="" cols="" rows="5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima aliquam porro enim iste recusandae, illum asperiores, dignissimos ratione, provident quam eaque doloribus! Tenetur quas quasi ipsa. Sunt, quia. Eos, distinctio.</textarea>

              <button className='p-btn'>Comment</button>
            </form>
          </section>: <></>} */}

          <section style={{ padding: "0" }} className="comments">
            <Comment />
            <Comment />
            <Comment />
            <Comment />
            <Comment />
            <Comment />
          </section>
        </div>
      </div>
    </>
  );
}
function Comment() {
  return (
    <>
      <div style={{ display: "flex", width: "100%", position: "relative" }}>
        <div style={{ width: "50px", height: "50px", position: "absolute" }}>
          <img
            style={{ objectFit: "cover", borderRadius: "40%" }}
            src="/assets/images/op.jpg"
            alt=""
            height="100%;"
            width="100%"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "74px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>User Name</p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Consequuntur tenetur veritatis ipsum vitae praesentium possimus
            fugiat. A repellat atque alias reiciendis tempora mollitia facere
            laboriosam omnis, voluptas rem deleniti fuga.
            <span style={{ display: "block", color: "grey" }}>1 days ago</span>
          </p>
        </div>
      </div>
    </>
  );
}
function ListOfRecommendations() {
  var list = [];

  for (let i = 0; i < 20; i++) {
    list.push(<VideoPreviewSmall video={GetVideo()} />);
  }

  return (
    <>
      <ul className="list-of-videos">{list}</ul>
    </>
  );
}
function VideoPreviewSmall({ video }) {
  return (
    <>
      <li className="my-video-card-small">
        <section>
          <a href="/play">
            <div className="video-preview-small">
              <img src="/images/ww.jpg" alt="" />
              <span className="duration">00:00:00</span>
            </div>
          </a>
        </section>
        <section style={{ width: "60%" }}>
          <div className="video-info-small">
            <div className="my-video-card-caption">
              <a href="" style={{ textDecoration: "none", color: "white" }}>
                <h6 style={{ lineBreak: "2" }}>{video.title}</h6>
              </a>
            </div>
            <div className="author-info">
              <div
                className="author-image"
                style={{ height: "25px", width: "25px" }}
              >
                <a href="">
                  <img src="/ww.jpg" alt="" />
                </a>
                <span></span>
              </div>
              <a href="" style={{ textDecoration: "none", color: "white" }}>
                {video.author}
              </a>
            </div>
          </div>
        </section>
      </li>
    </>
  );
}
