import { InputField } from "./GeneralSettings";

function BalanceSetting() {
    const withdrawOptions = ["Paypal", "WeChat", "Alipay", "Worldremmit", "Ecocash", "Mukuru"]
    return (
        <form>
            <p>Available Balance</p>
            <h2>USD 0.00</h2>
            <InputField label={"Max limit for your ads campaign"} id={"limit"} type={""} />
            <InputField label={"Withdraw to "} id={"withdraw_addr"} type={"selection"} options={withdrawOptions} />
            <InputField label={"Paypal Email"} id={"addr"} type={""}/>
            <InputField label={"Amount (Min $50)"} id={"limit"} type={""}/>
            <div style={{display:"flex"}}>
            <button className="p-btn">Submit</button>
            <button className="p-btn clicked">Withdrawals</button>
            </div>
            
        </form>
    )
}

export default BalanceSetting;