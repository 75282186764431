import User from "../../models/user.ts";
import "./profile.css";


function Profile({ uid }) {
  let user = new User();
  user.firstname = "Amanuel";
  user.lastname = "Tesfaye";
  user.numberofsubscribers = 0;

  return (
    <div className="my wrapper-profile">
      <div className="profile-info">
        <img className="cover-img" src="/images/ww.jpg"></img>
        <div className="profile-info-wrapper">
          <div className="my profile-picture empty ">
            <img
              className="profile-img"             
              src="/svg/upload.svg"
              alt=""
            ></img>
          </div>
          <div className="user-info">
            <h2>
              {user.firstname} {user.lastname}
            </h2>
            <p>{user.username}</p>
            <p>{user.numberofsubscribers} Subscribers</p>
            <ul className="interact-buttons">
              <li>
                <button onClick={(e) =>Subscribe(e)} className="p-btn">Subscribe</button>
              </li>
              <li>
                <button className="p-btn">Message</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <nav className="profile-nav">
        <ul>
          <li>Videos</li>
          <li>Playlists</li>
          
          <li>Liked Videos</li>
          <li>Purchased</li>
          <li>About</li>
        </ul>
      </nav>
      <section className="user-video-content">
        <ul className="category-list">
          <li className="category-wrapper <%= element.toLowerCase().replace(/\s+/g, '')%>">
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1 className="category-title">element</h1>
              <a href="">
                {" "}
                <h5>
                  Explore more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    className="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"
                    />
                  </svg>
                </h5>
              </a>
            </div>
            <hr style={{ color: "white" }} />
            <ul>
              <li className="my-video-card">
                <a href="/play">
                  <div className="video-preview">
                    <img src="/assets/images/ww.jpg" alt="" />
                    <span className="duration">00:00:00</span>
                  </div>
                </a>
                <div className="video-info">
                  <div className="author-image">
                    <a href="">
                      <img src="/assets/images/ww.jpg" alt="" />
                    </a>
                  </div>

                  <div className="my-video-card-caption">
                    <a href="">
                      <h5>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dolore porro ipsum earum quas sunt tempora explicabo
                        recusandae, iste numquam reprehenderit inventore
                        accusantium sequi. Quasi fugiat ex corporis facilis
                        soluta deleniti?
                      </h5>
                    </a>
                    <span>
                      <a href="">amana</a>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
}
export default Profile;

function Subscribe(e){
    e.target.classList.toggle("clicked")
    e.target.textContent = e.target.classList.contains("clicked")? "Subscribed" : "Subscribe"
}