import { useState } from "react";
import "../styles/navbar.css";
import { Link } from "react-router-dom";
import MessageCardPanel from "./MessageCardPanel";
import '../styles/login.css'
import {v4 as uuid} from 'uuid'

function Navbar({ user, setUser }) {
  const [closed, setClosed] = useState(true); 
  const toggleSideMenu = (e) => {
    setClosed(!closed);
  };
  const [formState, setState] = useState(false);
  const toggleForm = (e) => {
    setState(!formState);
  };
  const toggleAccMenu = () => {
    const accountMenu = document.querySelector(".account-dropdown-menu");
    accountMenu.classList.toggle("active");
    console.log("account-dropdown-menu");
  };
  return (
    <>
      <header>
        <nav className="nav-bar">
          <div className="top-left-part">

            <a href="/" className="logo">
              <img src="/images/logo.png" alt="" />
            </a>
          </div>

          <div className="search-bar">
            <form id="searchFrom" action="/search" method="get">
              <input type="text" placeholder="Search..." name="q" />
              <button type="submit">
                <img alt="" src="/svg/search.svg" />
              </button>
            </form>
          </div>
          <div>
            <ul className="my-nav-bar-top-right">
              {user.username ? (
                <>
                  <li className="create-btn" >
                    <button className="p-btn" onClick={(e)=>openMenu(e)}>
                      <img alt="" src="/svg/camrecorder.svg" />
                      Create
                    </button>
                    <ul className="drop-down ">
                      <li>                       
                        <Link>Upload</Link>
                        <img src="/svg/upload.svg" />
                      </li>
                      <li>
                        <Link>Go Live</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="notifications opened">
                    <Link onClick={OpenNotifcation} >
                      <img alt="" src="/svg/bell.svg" />
                    </Link>
                    <NotificationsList />
                  </li>
                  <li className="message-btn">
                <Link to="/messages">
                  <img alt="" src="/svg/msg.svg" />
                </Link>
              </li>
                </>
              ) : (
                ""
              )}

            

              <li className="search-btn-phone">
                <Link href="">
                  <img alt="" src="/svg/search.svg" />
                </Link>
              </li>

              <li className="my-account-btn" style={{ position: "relative" }}>
                <a>
                  {user.profileimg ? (
                    <img
                      onClick={toggleAccMenu}
                      src={user["profileimg"]}
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <img
                      onClick={toggleForm}
                      src={"/svg/badge.svg"}
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </a>
                <ul className="account-dropdown-menu">
                  <li>
                    <Link>{user.username}</Link>
                  </li>
                  <hr />
                  <li>
                    <Link to={'/subscriptions'}><img src="/svg/setting/verification.svg"></img>Subscriptions</Link>
                  </li>
                  <li>
                    <Link to='/wallet'><img src="/svg/wallet/topup.svg"></img>Wallet</Link>
                  </li>
                  <li>
                    <Link> <img src="svg/3by3dots.svg"></img><span>Playlists</span></Link>
                  </li>
                  <li>
                    <Link> <img src="/svg/clock-history.svg"></img>History</Link>
                  </li>
                  <hr />
                  <li>
                    <Link><img src="svg/thumbsUpfill.svg"/>Liked Videos</Link>
                  </li>
                  <li>
                    <Link> <img src="svg/newspaper.svg"/>My articles</Link>
                  </li>
                  <li>
                    <Link><img src="svg/building-fill-gear.svg"/>Video Studio</Link>
                  </li>
                  <li>
                    <Link to='settings/my_information'><img src="svg/pencil-square.svg"/>Edit</Link>
                  </li>
                  <li>
                    <Link to='settings/general'><img src="svg/setting/general.svg"/>Settings</Link>
                  </li>
                  <li>
                    <Link><img src="svg/newspaper.svg"/>Advertising</Link>
                  </li>
                  <hr />
                  <li>
                    <Link><img src="svg/box-arrow-left.svg"/>Logout</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <TopExploreLinks/>
      </header>
      
      <nav className={closed ? "lsnb closed" : "lsnb"}>
        <ul>
          <li>
          <div onClick={toggleSideMenu} >
              <img alt="" src="/svg/3by3dots.svg" />
            </div>
          </li>
          <li>
            <a href="/">
              <div>
                <img alt="" src="/svg/house.svg" />
                <span>Home</span>
              </div>
            </a>
          </li>
          <li>
            <a href="/explore/premiumcontent">
              <div>
                <img alt="" src="/svg/star.svg" />
                <span>Premium</span>
              </div>
            </a>
          </li>
          <li>
            <a href="https://www.omstudio.me">
              <div>
                <img alt="" src="/svg/market.svg" />
                <span>Marketplace</span>
              </div>
            </a>
          </li>
          <li>
            <Link to="/shorts/rand">
              <div>
                <img alt="" src="/svg/play.svg" />
                <span>Shorts</span>
              </div>
            </Link>
          </li>
          <li>
            <a href="/explore/movies">
              <div>
                <img alt="" src="/svg/film.svg" />
                <span>Movies</span>
              </div>
            </a>
          </li>
        </ul>
        <footer className="lsnb-footer">
          <ul
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <li>
              <a href="https://www.omstudio.me">
                Refund Policy
              </a>
            </li>
            <li>
              <a href="">FAQs</a>
            </li>
            <li>
              <a href="https://www.omstudio.me">Terms of use</a>
            </li>
            <li>
              <a href="https://www.omstudio.me">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://www.omstudio.me">About us</a>
            </li>
            <li>
              {" "}
              <a href="https://www.omstudio.me">Contact us</a>
            </li>
            <li>
              <p className="copyright">
                Copyright © 2023 OMStudio Distribution Pvt Ltd. All rights
                reserved.
              </p>
            </li>
          </ul>
        </footer>
      </nav>
      {formState ? (
        <MessageCardPanel
          toggleForm={toggleForm}
          isOn={formState}
          setUser={setUser}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Navbar;
function  NotificationsList (){

  return (
    <div className="list-of-notifications" >
    <p>No new notification</p>
    <hr/>
    
    </div>
  )
  
}
function openMenu(e){
    console.dir(e.target)
    e.target.parentElement.children[1].classList.toggle("opened")
}
function OpenNotifcation(){
  const list = document.querySelector('.notifications');
  list.classList.toggle("opened")
  

}
function TopExploreLinks() {
  const tags = [
    "Recommended For You",
    "Latest Videos",
    "Shorts",
    "Top Videos",
    "Film",
    "Music",
    "Sports",
    "Documentary",
    "Travel & Events",
    "Gaming",
    "People & Blogs",
    "Comedy",
    "Entertainment",
    "News & Politics",
    "How-to-style",
    "Business",
  
  ];
  var navLinks = [];
  tags.forEach((tag) => {
    navLinks.push(
      <li key={uuid()}>
        <Link>{tag}</Link>{" "}
      </li>
    );
  });
  return <ul className="top-nav-link-container">{navLinks}</ul>;
}