import { InputField } from "./GeneralSettings";

function PasswordSettings(){
return(
    <form>
        <InputField label={"Current Password"} id={"cPassword"} type={""}/>
        <InputField label={"New Password"} id={"newPassword"} type={""}/>
        <InputField label={"Confirm Password"} id={"confirmPassword"} type={""}/>
        <hr />
        <button className="p-btn">Save</button>
    </form>
)
}
export default PasswordSettings;