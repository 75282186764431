import { InputField } from "./GeneralSettings";
function MonetizationSetting() {
    const message = "Earn ($0.05) for each advertisement click you get from your videos!"
    const desription = "Subscription Price (How much users will pay to subscribe to your channel)"
    return (
        <>            
            <form className="monetize-form">
            <div>
                <h1>{message}</h1>
                </div>
                <InputField label={desription} id={"subsc-price"} type={""}/>
                <hr />
                <button className="p-btn">Save</button>
            </form>
        </>)

}
export default MonetizationSetting;