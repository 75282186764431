class Video {
  title: string;
  description: string;
  url: string;
  duration: "00:01:00";
  author_id: string;
  video_id: string;
  thumbnail_url: string;
  comments: Comment[];
  number_of_views: number;
  number_of_likes: number;
  rating: number;
  quality: string;
  year : number;

  constructor(
    video_id: string,
    title: string,
    description: string,
    url: string,
    thumbnail_url: string,
    author_id: string,
    number_of_views: number,
    number_of_likes: number
  ) {}
}

export default Video;

class Comment{
  message:string;
  author_id:string;
  likes: number;
  replies:Comment[];
  constructor(
    message:string,
    author_id:string,
    likes: number,
    replies:Comment[]
  ){
    this.message = "";
    this.author_id = "";
    this.likes = 0;
    this.replies = [];
  }
}