import { useState } from "react";
import Movies from "./movies";
import ExplorePremium from "./PremiumVideos";
import Shorts from "../Shorts/shorts";

function Explore() {
    var display;
    console.log(window.location.pathname.split('/')[2])

    switch (window.location.pathname.split('/')[2]) {
        case "movies":
            display = <Movies />
            break;
        case "shorts":
            display = <Shorts />
            break;
        case "premiumcontent":
            display = <ExplorePremium />
            break;
        case "articles":
            break;
        case "shops":
            break;
        case "music":
            break;
        case "comedy":
            break;
        case "sports":
            break;
        case "bussines":
            break;
        case "mostwatched":
            break;
    }
    return (
        <>
            {display}
        </>)
}

export default Explore;
