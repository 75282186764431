import { Link } from "react-router-dom";
import User from "../../models/user.ts";
import './subscription.css'

function SubscriptionsPage(){
    let user = new User();
    user.username = "Aman";
    user.author_id = "AMAN"
    user.profileimg = "/images/pp.jpg"
    const listOfUsers = [];

    for(let i=0; i<40; i++){
        listOfUsers.push(  <UserCard item={user}/>)
    }
    return(
        <div className="wrapper">
            
            <div className="sub-category-title">
                <img src="/svg/setting/verification.svg" />
                <span>Subscriptions</span>
                </div>
            <hr />
            <ul className="list-of-users">
             {listOfUsers}
            </ul>

        </div>
    )
   
}
export default SubscriptionsPage;

function UserCard({item}){
    return( 
    <li className="user-card">
    <Link className="user-pic" to="/">
      <img src={item.profileimg} alt="" />
    </Link>
    <div className="user-info">
      <Link to="">
        <h5>{item.author_id}</h5>
      </Link>
      
    </div>
    <p>1 Subscriber</p>
  </li>)
}