import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/home";
import Shorts from "./pages/Shorts/shorts.js";
import VideoPlayPage from "./pages/PlayVideoPage/videoplay.js";
import Explore from "./pages/Explore/explore";
import Profile from "./pages/Profile/Profile.js";
import Wallet from "./pages/Wallet/Wallet";
import Settings from "./pages/Settings/Settings";
import Upload from "./pages/Upload/Upload";
import User from "./models/user.ts";

import "./styles/App.css";

import SubscriptionsPage from "./pages/Subscriptions/SubscriptionPage.js";
import MessagesPage from "./pages/Messages/MessagesPage.js";
import ShopView from "./pages/Explore/ShopView.js";
import Layout from "./pages/Layout.js";

function App() {
  const [user, setUser] = useState(new User());
  //connectDb();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout user={user} setUser={setUser} />}>
            <Route
              index
              element={
              <Home homePageCategory={user.homePageCategory} 
              />}
            />
            <Route path="/explore/:path" element={<Explore />} />
            <Route path="/play/:video_id" element={<VideoPlayPage />} />
            <Route path="/subscriptions" element={<SubscriptionsPage />} />
            <Route path="/shorts/:video_id" element={<Shorts />} />
            <Route path="/settings/:option" element={<Settings />} />
            <Route path="/shop/:id" element={<ShopView />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/profile">
              <Route index element={<Profile />} />
              <Route path="/profile/:id/videos" element={<Profile />} />
              <Route path="/profile/:id/playlists" element={<Profile />} />
              <Route path="/profile/:id/liked_videos" element={<Profile />} />
              <Route path="/profile/:id/purchased" element={<Profile />} />
              <Route path="/profile/:id/about" element={<Profile />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

