import { useState } from "react";
import { Link } from "react-router-dom";
import HandleLoginRequest from "../utils/user";

function LoginForm({ setUser, setForm, toggleForm }) {
  const [formData, setData] = useState({ username: "", password: "" });
  const [isFound, setIsFound] = useState(null);

  function handleChangeEvt(e) {
    const fieldName = e.target.name;
    const value = e.target.value;

    setData((currentData) => {
      currentData[fieldName] = value;
      return { ...currentData };
    });
  }

  return (
    <>
      <h2 className="card-title">Let's sign you in</h2>
      <p className="card-text">Welcome back! you've been missed</p>
      {isFound === false ? (
        <p style={{ color: "red", textAlign: "center" }}>
          User name or password did not match
        </p>
      ) : (
        ""
      )}

      <form
        onClick={(e) => e.stopPropagation()}
        className="login-frm"
        action="/login"
        method="post"
      >
        <fieldset>
          <input
            onChange={handleChangeEvt}
            required
            type="text"
            className="input-field"
            id="inputEmail3"
            placeholder="Username/Email/Phone Number"
            name="username"
            value={formData.username}
          />

          <input
            onChange={handleChangeEvt}
            required
            type="text"
            className="input-field"
            id="inputPassword3"
            placeholder="Password"
            name="password"
            value={formData.password}
          />
        </fieldset>
        <fieldset>
        <label className="form-check-label" htmlFor="gridCheck1">
            <input
              className="form-check-input"
              type="checkbox"
              id="gridCheck1"
            />
            Remember me
          </label>
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              HandleLoginRequest(formData, setUser, setIsFound, toggleForm);
            }}
            type="submit"
            className="btn btn-primary"
          >
            Login
          </button>
          <Link to="/resetpassword">Forgot your password?</Link>

      

          <Link onClick={() => setForm("register")} id="registerFrmbtn">
            Don't have an account? Sign up
          </Link>
        </fieldset>
      </form>
    </>
  );
}
export default LoginForm;
