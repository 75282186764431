import './shorts.css'
function Shorts() {
    return (
        <div className="wrapper-shorts">
            <ShortsList />
        </div>
    )
}
export default Shorts;

function ShortsList() {
    return (        
        <ul>
            <Short isPlaying={false} position='left' />
            <Short isPlaying={true} />
            <Short isPlaying={false} position='right' />
        </ul>
        )
}
function Short({ isPlaying, position }) {
    return (
        <>
            {isPlaying ?
                <li className="item">
                    <div className="active-item">
                        <video id="vid" autoplay muted playsInline></video>
                        <section className="video-interact">
                            <ul>
                                <li>
                                    <img src='/svg/thumbsUpfill.svg'/>
                                </li>
                                <li>                                     
                                    <a href="/shorts/id"><img src='/svg/msg.svg'/></a>
                                </li>
                                <li>
                                <img src='/svg/flag-fill.svg'/>
                                </li>
                            </ul>
                        </section>
                        <section className="video-content">
                            <div><p>amantes30</p></div>
                            <div style={{ width: "100%" }}>
                                <p>
                                    Valorant Deathmatch 100 kills
                                </p>
                            </div>
                            <form className="comment-input">
                                <input type="text" placeholder="Comment" />
                                <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                </button>
                            </form>
                        </section>

                    </div>
                </li>
                :
                <li className={"item " + position}>
                    <div></div>

                    <video src="" autoplay muted playsInline></video>
                </li>
            }
        </>
        )

}