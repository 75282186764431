import UploadVideo from "./UploadVideo";
import './upload.css'


function Upload({type}){
    return(
    <>
    <div className="wrapper">
        <UploadVideo />
    </div>
    </>)

}

export default Upload;