import { useState } from "react";
import { Link } from "react-router-dom";
import { HandleRegisterRequest } from "../utils/user";
import User from "../models/user.ts";
import axios from "axios";

function RegisterForm({ setForm }) {
  const [formData, setData] = useState({
    username: "",
    password: "",
    email: "",
  });

  const [user, setUser] = useState(new User());

  function handleChangeEvt(e) {
    const fieldName = e.target.name;
    const value = e.target.value;

    setData((currentData) => {
      currentData[fieldName] = value;
      return { ...currentData };
    });
  }
  return (
    <form id="rf" method="post" className="register-frm">
      <ul>
        <li>
          <input
            onChange={handleChangeEvt}
            type="text"
            className="input-field"
            placeholder="First Name"
            name="firstname"
          />
          <input
            onChange={handleChangeEvt}
            type="text"
            className="input-field"
            placeholder="Last Name"
            name="lastname"
          />
        </li>
        <li>
          <input
            onChange={handleChangeEvt}
            type="text"
            className="input-field"
            placeholder="Username"
            name="username"
          />
        </li>
        <li>
          <input
            onChange={handleChangeEvt}
            type="email"
            className="input-field"
            placeholder="Email"
            name="email"
          />
        </li>
        <li>
          <input
            onChange={handleChangeEvt}
            type="password"
            className="input-field"
            placeholder="Password"
            name="password"
          />
        </li>
        <li>
          <input
            onChange={handleChangeEvt}
            type="password"
            className="input-field"
            placeholder="Confrim Password"
          />
        </li>

        <li>
          <button
            onClick={(e) => {
              e.preventDefault();
              const newUser = {
                ...user,
                firstname: formData.firstname,
                lastname: formData.lastname,
                username: formData.username,
                email: formData.email,
                password: formData.password,
              };
              console.log(newUser);
              axios
                .post("/users.json", newUser)
                .then((response) => {
                  console.log(newUser);
                })
                .catch((err) => {
                  console.log(err);
                });
              //HandleRegisterRequest();
            }}
            form="rf"
            type="submit"
            className="btn btn-primary"
          >
            Register
          </button>
        </li>
        <li>
          <Link onClick={() => setForm("login")}>
            Already have an account? Login
          </Link>
        </li>
      </ul>
    </form>
  );
}

export default RegisterForm;
