import { useState } from "react";
import Video from "../../models/video.ts";
import { InputField } from "../Settings/components/GeneralSettings";

function UploadVideo() {

    return (
        <form>
            <h1>Upload Video</h1>
            <div>
                <h1>Drag and drop video files to upload</h1>
                <p>Your videos will be private until you publich them</p>
            </div>
            <button className="p-btn">Upload</button>
        </form>
    )

}
export default UploadVideo;

function DetailsForm() {
    const [video, setVideo] = useState(new Video());

    return (
        <form>
            <div>
                <InputField label={"Video Title"} id={"vid-title"} type={""} />
                <InputField label={"Video Description"} id={"description"} type={"ta"} />
                <h1>Thumbnail</h1>
                <p>Select or upload a picture that shows what's in your video. A good thumbnail stands out and draws viewers' attention</p>

                <button className="p-btn"> Next Step</button>
            </div>
        </form>
    )
}