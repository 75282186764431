import axios from "axios";

//for home page
export async function loadVideoByCategory(category_title, amount, setList) {
  if (
    category_title === "premiumcontent" ||
    category_title === "shorts" ||
    category_title === "articles" ||
    category_title === "shops"
  ) {
    axios
      .get(`/${category_title}.json`)
      .then((response) => {
        const temp = response.data;
        

        const vid = response.data.filter(
          (item) => item.category === category_title
        );
        setList(temp);
        //console.log("hey")
      })
      .catch((err) => {
        console.log(err);
      });
  }
  else{
    axios.get('/videos.json')
    .then((response) => {   
        //const vid = response.data.filter(item => item.category === category_title);
        const temp = response.data;
        

        const vid = response.data.filter(
          (item) => item.category === category_title
        );
        setList(temp);
        
        //console.log("hey")
    })
    .catch((err) =>{
        console.log(err);
    })
  }
  switch (category_title) {
    case "premiumcontent":
     // loadPremiumVideos(amount);
      break;
    case "shorts":
      break;
    case "articles":
      break;
    case "movies":
      break;
    case "blogs":
      break;
    case "shops":
      break;
    case "how-to-style":
      break;
    case "articles":
      break;
    case "sports":
      break;
    case "comedy":
      break;
    case "business":
      break;
    case "music":
      break;
    case "gaming":
      break;
    case "latestvideos":
      break;
    case "mostwatched":
      break;
  }

  
}

export function loadPremiumVideos(amount) {}
