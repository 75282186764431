import './messages.css'

function MessagesPage() {
    document.querySelector("title").innerText = "Castlly | Messages";
    return (
        <div className="wrapper messages">
            <ListOfMessages />
            <ChatBox />


        </div>
    )

}
export default MessagesPage;
function MsgPreviewCard() {
    return (
        <li className="prev-card">
            
                <div className="p-picture">
                    <img src='/images/pp.jpg' />
                </div>
                <div className="u-info">
                    <h4>Amisha</h4>
                    <p>loresdcvhjkbsdackhjdsbvksajdhbcdaksjhdcbkdahbccbbbbbbbhbjkh</p>
                </div>
          
        </li>


    )
}
function ListOfMessages() {
    return (
        <>
          <ul className="list-of-msgs">
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
            <MsgPreviewCard />
        </ul>
        </>
      
    )
}
function ChatBox(){
    return(
        <div className='chat-box'>

            <div className='message-input'>
                <form>
                    <input placeholder='Type message...'></input>
                    <button className='p-btn' >Send</button>
                </form>

            </div>
        </div>
    )
}