import '../../styles/movies.css'

import Video from '../../models/video.ts';
import { InputField } from '../Settings/components/GeneralSettings.js';
function Movies() {
    document.querySelector('title').innerText = "Castlly | Movies"

    return (
        <div className="wrapper-movies">
            <Header />
            <ListOfMovies />
        </div>
    )
}
export default Movies;


function Header() {
    const GenreList = ["Comedy", "Action", "Romance"]
    const CountryList = ["China", "Russia", "Zimbwabwe", "Ethiopia"]
    return (
        <><div className="category-title-container">
            <h1 className="category-title">Movies</h1>
            <InputField label={"Genre"} id={"genre"} type={"selection"} options={GenreList}/>
            <InputField label={"Country"} id={"country"} type={"selection"} options={CountryList}/>
            
            <form action="" style={{ position: "relative" }}>
                <button className='sort-nav-btn' onClick={(e) => {
                    e.preventDefault()
                    const sortoptionlist = document.querySelector(".sort-type-options");
                    sortoptionlist.classList.toggle('active')
                }}>
                    <h3>Sort</h3>
                </button>
                <div className='sort-type-options'>
                    <ul >
                        <li>Rating</li>
                        <li >ReleaseDate</li>
                        <li >Most Watched</li>
                    </ul>
                </div>

            </form>

        </div>
            <hr style={{ color: "white" }} />

        </>)
}

function ListOfMovies() {
    let movies = []
    for (var i = 0; i < 20; i++) {
        movies.push(<MoviePreviewCard key={i} />)
    }
    return (
        <>
            <ul className='list-of-movies'>
                {movies}
            </ul>
        </>
    )

}

export function MoviePreviewCard() {
    const mv= new Video();
    mv.title = "Fast 4";
    mv.thumbnail_url = "/images/ww.jpg";
    mv.rating = 9;
    mv.quality = "TS"
    mv.year = 1999;
    
    return (
        <>
            <li className="movie-card">
                <a href="/play">
                    <div className="movie-thumbnanil">
                        <img className="m-preview-img" src={mv.thumbnail_url} alt="" />
                        <span className="duration">{mv.rating} | {mv.quality}-TV</span>
                    </div>
                </a>
                <div className="movie-info">
                    <h5>{mv.year}</h5>
                    <div className="video-card-caption">
                        <a href="">
                            <h4>{mv.title}</h4>
                        </a>

                    </div>
                </div>
            </li>

        </>
    )
}