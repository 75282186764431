import { Link, useParams } from "react-router-dom";
import './styles/settings.css'
import GenralSettingFrom from "./components/GeneralSettings";
import MonetizationSetting from "./components/MonetizationSetting";
import PasswordSettings from "./components/PasswordSettings";
import DeleteAccount from "./components/DeleteAccount";
import BalanceSetting from "./components/BalanceSetting";

function Settings() {
  document.querySelector("title").innerText = "Castlly | Acount Setting";
  const { option } = useParams();
  var choice;
  var link;
  switch (option) {
    case "general":
      choice = <GenralSettingFrom />
      break;
    case "monetization":
      choice = <MonetizationSetting />
      break;
    case "password":
      choice = <PasswordSettings />
      break;
    case "delete_account":
      choice = <DeleteAccount />
      break;
    case "balance":
      choice =<BalanceSetting />
      break;
  }
  function SettingLink({ text, link }) {

    return (
      <>
        <li className={option === link ? "selected" : ""}>
          <Link to={"/settings/" + link}><img alt="" src={'/svg/setting/' + link + ".svg"} /><span className="link-text">{text}</span></Link>

        </li>

      </>

    );
  }
  return (
    <div className="wrapper settings">
      <ul className="setting-link-list">
        <SettingLink text={"General"} link={"general"} />
        <SettingLink text={"Monetization"} link={"monetization"} />
        <SettingLink text={"Password"} link={"password"} />
        <SettingLink text={"Balance"} link={"balance"} />
        <SettingLink text={"Avatar/Cover"} link={"avatar"} />
        <SettingLink text={"Verification"} link={"verification"} />
        <SettingLink text={"Blocked Users"} link={"blocked_users"} />
        <SettingLink text={"Invitation Links"} link={"invitation_links"} />
        <SettingLink text={"Manage Sessions"} link={"manage_sessions"} />
        <SettingLink text={"My Information"} link={"my_information"} />
        <SettingLink text={"Delete Account"} link={"delete_account"} />
      </ul>
      <section className="content">
        {choice}
      </section>

    </div>
  );
}

export default Settings;
