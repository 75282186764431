function PromotedVideo() {
  return (
    <>
      <section className="top-videos-slider">
        <VideoSlideShow />
      </section>
    </>
  );
}
export default PromotedVideo;

function VideoSlideShow() {
  return (
    <div
      id="carouselExampleInterval"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <SlideShowItem />
      <SlideControlButtons />
    </div>
  );
}
function SlideShowItem() {
  return (
    <div className="carousel-inner">
      <div className="carousel-item active" data-bs-interval="10000000">
        <Item />
      </div>
      <div className="carousel-item" data-bs-interval="5000">
        <Item />
      </div>
      <div className="carousel-item" data-bs-interval="5000">
        <Item />
      </div>
    </div>
  );
}
function Item() {
  return (
    <>
      <div className="promoted-video">
        <img src="/images/ww.jpg" alt="" />
        <div className="promoted-video-info">
          <h3 style={{color: "tan"}}>Featured item</h3>
          <h1>How Inter Miami Signed Lionel Messi</h1>
          <h1>Genre : Sports</h1>
          <button className="p-btn">Watch</button>
         
        </div>
     
      </div>
    </>
  );
}
function SlideControlButtons() {
  return (
    <>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleInterval"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </>
  );
}
