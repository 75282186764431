import User from "../../models/user.ts";
import ItemsCategoryList from "../Home/ItemsCategoryList.js";



function ExplorePremium() {
  const Catergories = ["Movies", "Articles", "Music Videos"];
  

  return  ( <>
  <div className="wrapper-cont">
  <ItemsCategoryList listOfCategory={Catergories} />
  </div>
   
 
  </>);
}

export default ExplorePremium;
