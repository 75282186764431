import axios from "axios";

import User from "../models/user.ts";

const HandleLoginRequest = async (formData, setUser, setIsFound, toggleForm)=>  {
  return await loadData(formData, setUser, setIsFound, toggleForm);
}
export default HandleLoginRequest;

async function loadData(formData, setUser, setIsFound, toggleForm) {
  const { username, password } = formData;

  await axios
    .get("/users.json")
    .then((response) => {
      const users = response.data;
      const user = users.find(
        (u) =>
          (u.username === username && u.password === password) ||
          (u.email === username && u.password === password)
      );

      if (user) {
        console.log("Success");
        const temp = {
          ...new User(),
          username: user.username,
          firstname: user.firstname,
          profileimg: user.profileimg,
        };
        setUser(temp);
        setIsFound(true);
        toggleForm();
        return user;
      } else {
        console.log("fail");
        setIsFound(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export const HandleRegisterRequest = async (formData)=>{
  
}
function addUser(formData){
  

}
function findUserByID(id){
  
  axios.get('/user.json')
  .then((response) => {
    const users = response.data;
    
    const user = users.find((u)=> u.author_id === id);
    
    return user;
  })
  .catch(err =>{
    console.log(err);
  })
}
