import PromotedVideo from "./PromotedVideoSlider";
import ItemsCategoryList from "./ItemsCategoryList";
import { Link, Outlet } from "react-router-dom";
import "./home.css";




function Home({ homePageCategory }) {
  document.querySelector("title").innerText = "OMStudio | Home";
  return (
    <>
      <div className="wrapper home">
       
        <PromotedVideo />
        <section className="wrapper-home-content">
          <ItemsCategoryList listOfCategory={homePageCategory} />
        </section>
        <Outlet />
      </div>
    </>
  );
}

export default Home;


